.table-head{
    display: flex;
    border-top: solid 1.3px #D8DEE3;
    border-bottom: solid 0.9px #eee;
    padding-top:8px;
    padding-bottom:5px;
    
    
}

.table-rows-container::-webkit-scrollbar{
    display: none;
}

.table-horizontal{
    display: flex;

    border-bottom: solid 0.9px #eee;
    padding-top:8px;
    padding-bottom:5px;
}

.table-head-item{
    font-size: 0.8em;
    font-weight: 500;
}

.table-row-item{
    font-size: 0.9em;
    font-weight: 300;
    color: #777;
}

/* .table-head-checkbox{
    border: 2px solid blue;
    outline: none;
} */

/* input[type="checkbox"] {

    appearance: none;
    border: 1px solid #D8DEE3; 
    height: 0.9em;
    width: 0.9em;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 3px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.4);
  box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.4);

} */