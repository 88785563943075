.form-field-input{
    border: solid #ccc 1px;
    border-radius: 5px;
    font-size: 0.9em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

}

.form-field-input:focus{
    outline: none;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
    -moz-box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
    box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
}

.form-field-title{
    font-size: 0.9em;
    font-weight: 600;
    color: #414552;
}

.form-header{
    font-weight: 500;
    border-bottom: solid 1px #eee;
    padding-bottom: 6px;
    color: #414552;
}

.custom-dropdown{
    width: 200px;
    padding-right: 2px;
    outline: none;
}

.custom-dropdown {
    width: 200px;
    padding: 10px;
    border: none;
    background-color: white;
    font-size: 0.9em;
    appearance: none;
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}


.custom-dropdown-holder{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    border-radius: 5px;
    border: solid #ccc 1px;
    color: #414552;
    font-size: 0.9em;
    font-weight: 500;
    min-height: 37px;
}

.custom-dropdown-holder-focus{
    -webkit-box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
    -moz-box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
    box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
}