@keyframes enter {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .animate-enter {
    animation: enter 0.5s ease-out;
  }
  
  @keyframes leave {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  .animate-leave {
    animation: leave 0.5s ease-out forwards;
  }
  