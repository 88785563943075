.footer-component{

    height: 80px;
    border-top: solid 1px #eee;
    display: flex;    
    justify-content: flex-end;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 80px;
}

@media (max-width: 768px) {
    
}