.textfield{
    border: solid 1px #ccc;
    border-radius: 5px;
    font-size: 0.9em;
    outline:none;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.textfield:focus{
    -webkit-box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
    -moz-box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
    box-shadow: 0px 0px 0px 3px rgba(165,204,237,1);
}