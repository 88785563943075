.info-hover-container{
    position: absolute;
    font-size: 0.8em;
    background-color: white;
    border: solid 1px #eee;
    border-radius: 3px;
    color: #555;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

