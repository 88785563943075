.vertical-box-container{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
    border: solid #D9D9D9 0.5px;

}

.vertical-box-container-no-border{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
}